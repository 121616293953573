import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";

const Leads = () => {
  const [status, setStatus] = useState(null);
  const [open, setOpen] = useState(false);

  // timer for close modal
  const timer = useRef(null);
  useEffect(() => {
    timer.current = setTimeout(() => {
      if (!open && window.localStorage.getItem("closeType") !== "forever") {
        setOpen(true);
      }
    }, 5000);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [open]);

  // close modal
  const changeCloseType = () => {
    if (typeof window !== "undefined") {
      window.localStorage.setItem("closeType", "forever");
      setOpen(false);
    }
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  //Submit the form
  const onSubmit = (data) => {
    let url = `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v1/gatsby/sendgridList`;

    const method = "post";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.NEXT_PUBLIC_GATSBY_API_TOKEN}`,
    };

    fetch(url, {
      method,
      headers,
      body: JSON.stringify({ email: data.email, name: data.name }),
    })
      .then((res) => {
        if (!res.ok) throw res;
        return res.json();
      })
      .then((res) => {
        setStatus("success");
        setShowModal(true);
        reset({
          name: "",
          email: "",
          vendor: "all",
        });
      })
      .catch((err) => {
        setStatus("failed");
        console.log(err);
      });
  };
  return (
    <div className={open ? "modal fade show block" : "modal fade"}>
      <div className="modal-dialog max-w-4xl ">
        <div className="modal-content">
          {/* <!-- Body --> */}
          <div className="modal-body p-3">
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                changeCloseType();
                setOpen(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                className="fill-jacarta-700 h-6 w-6 dark:fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
              </svg>
            </button>

            <div className="flex items-center justify-between mt-2">
              <div className="md:w-[36rem] lg:w-46 p-4 rounded hidden md:block dark:bg-jacarta-600">
                <img
                  src="/images/home.svg"
                  alt="logo"
                  className="rounded lg:w-[34rem] "
                />
              </div>
              {status === "success" ? (
                <div className="md:w-[50%] flex flex-col justify-center items-center">
                  <div
                    className="dark:border-jacarta-600 bg-green flex h-10 w-10 items-center justify-center rounded-full border-2 border-white"
                    data-tippy-content="Verified Collection"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className="h-[.875rem] w-[.875rem] fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                    </svg>
                  </div>
                  <p className="text-jacarta-700 text-lg font-display px-8 py-5 dark:text-accent">
                    Thanks for subscribing!
                  </p>
                </div>
              ) : status === "failed" ? (
                <div className="flex md:w-[70%] justify-center items-center">
                  <p className="text-jacarta-700 text-lg text-center font-display dark:text-accent px-8 py-5">
                    Something went wrong! Please try later
                  </p>
                </div>
              ) : (
                <div className="p-3">
                  <p className="text-jacarta-700 mx-auto mt-18 mb-4 max-w-2xl text-center text-lg dark:text-white">
                    Join our mailing list to receive timely updates from your
                    favorite truckloads.
                  </p>

                  <div className="mx-auto">
                    <div className="grid grid-col grid-cols-1 gap-2">
                      <div className="mb-2">
                        <label
                          htmlFor="cname"
                          className="font-display text-jacarta-700 text-sm dark:text-white ml-1"
                        >
                          Full Name<span className="text-red">*</span>
                        </label>
                        <input
                          type="text"
                          id="cname"
                          {...register("name", {
                            required: {
                              value: true,
                              message: "Fullname required",
                            },
                            minLength: {
                              value: 2,
                              message:
                                "Name should ne be less than 2 charachters",
                            },
                            maxLength: {
                              value: 30,
                              message:
                                "name should not be longer than 30 charachter",
                            },
                          })}
                          className="dark:bg-jacarta-700 dark:border-jacarta-600 focus:ring-accent border-jacarta-100 w-full rounded-full border py-2 px-4 dark:text-white dark:placeholder-white"
                          placeholder=""
                        />
                        {errors.name && (
                          <p className="text-red">{errors.name?.message}</p>
                        )}
                      </div>
                      <div className="mb-2">
                        <label
                          htmlFor="item-name"
                          className="font-display text-jacarta-700 text-sm dark:text-white ml-1"
                        >
                          Email<span className="text-red">*</span>
                        </label>
                        <input
                          type="text"
                          id="item-name"
                          {...register("email", {
                            required: {
                              value: true,
                              message: "Email required",
                            },
                            pattern: {
                              value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                              message: "Please provide a valid email",
                            },
                          })}
                          className="dark:bg-jacarta-700 dark:border-jacarta-600 focus:ring-accent border-jacarta-100 w-full rounded-full border py-2 px-4 dark:text-white dark:placeholder-white"
                          placeholder=""
                        />
                        {errors.email && (
                          <p className="text-red">{errors.email?.message}</p>
                        )}
                      </div>
                    </div>

                    <div className="flex items-center justify-center pt-5 space-x-4">
                      <button
                        onClick={handleSubmit(onSubmit)}
                        className="bg-accent shadow-accent-volume hover:bg-accent-dark inline-block rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
                      >
                        Subscribe
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Leads;
