import React from "react";
import { USA_STATES_CAPITALS } from "../../utils";
import Link from "next/link";
import { HeadLine } from "../component";

const PalletsNearMe = () => {
  return (
    <section className="dark:bg-jacarta-800 relative py-8 md:py-8">
      <div className="container">
        <HeadLine
          text="Find Sellers By City"
          classes="font-display text-jacarta-700 mb-12 text-center text-3xl dark:text-white"
        />
        <div className="scrollbar-custom overflow-x-auto mt-5">
          <div
            role="table"
            className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 lg:rounded-2lg w-full min-w-[736px] border bg-white text-sm dark:text-white"
          >
            <div className="grid grid-cols-5 p-4">
              {USA_STATES_CAPITALS.sort(function (a, b) {
                if (a.city < b.city) {
                  return -1;
                }
                if (a.city > b.city) {
                  return 1;
                }
                return 0;
              }).map((item) => {
                const { id, city } = item;
                return (
                  <Link href={`/pallets-liquidation/${city}`} key={item.id}>
                    <button
                      key={id}
                      className="dropdown-item font-display text-jacarta-700 dark:hover:bg-jacarta-600 hover:bg-jacarta-50 flex w-full items-center justify-between rounded-xl px-5 py-2 text-left text-sm transition-colors dark:text-white"
                    >
                      {city}
                    </button>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PalletsNearMe;
