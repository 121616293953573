import Link from "next/link";
import React from "react";

const BecomeSeller = () => {
  return (
    <div className="container">
      <div className="mx-auto">
        <div className="dark:bg-jacarta-700 rounded-2xl my-8 items-center grid grid-col md:grid-cols-2  bg-white p-8">
          <div className="w-full p-6">
            <img
              src="/images/become-seller.png"
              alt="author"
              className="mr-4 w-full shrink-0 self-start rounded-lg md:mr-8"
            />
          </div>
          <div>
            <p className="uppercase font-display text-sm text-accent dark:text-jacarta-300 mb-4">
              Likizone for liquidation business
            </p>
            <span className="text-jacarta-700 font-display mb-3 mt-2 block text-xl dark:text-white">
              Are you a liquidation business interested in reaching new buyers
            </span>

            <ul className="list-disc pl-3">
              <li className="dark:text-jacarta-300">
                Reach customers in your area or globally looking for legit
                sellers
              </li>
              <li className="dark:text-jacarta-300 ">
                Move your inventory fast with easy and efficient advanced
                listings
              </li>
              <li className="dark:text-jacarta-300 mb-4">
                Strengthen your online reputation with verified revies
              </li>
            </ul>
            <div className="flex space-x-5">
              <Link href="/create">
                <button className="bg-accent shadow-accent-volume hover:bg-accent-dark inline-block rounded-full py-3 px-8 text-center font-semibold text-white transition-all">
                  {" "}
                  List your business now
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BecomeSeller;
