import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { acceptedCookies } from "../../redux/counterSlice";

const CookiesConsent = () => {
  const { isAccepted } = useSelector((state) => state.counter);
  const dispatch = useDispatch();
  const accept = () => {
    dispatch(acceptedCookies());
  };

  if (isAccepted) {
    return null;
  }

  return (
    <div>
      <div className="modal-content z-[100] h-fit flex flex-col items-start justify-center fixed py-5 bottom-2 w-[90vw]">
        <div>
          <p className="px-10 text-jacarta-700 dark:text-white ">
            We use cookies on our website to give you the most relevant
            experience by remembering your preferences and repeat visits. By
            clicking 'Accept' or continuing to browse our website, you consent
            to the use of cookies.
          </p>
        </div>
        <div className="px-10 mt-5">
          <button
            onClick={accept}
            className="bg-accent shadow-accent-volume hover:bg-accent-dark inline-block rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
          >
            Accept
          </button>
          <button
            onClick={accept}
            className="border-accent border-2 text-accent hover:bg-accent-dark hover:text-white ml-2 inline-block rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
          >
            Decline
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookiesConsent;
