import Link from "next/link";
import React from "react";
import HeadLine from "../headLine";

const Team = ({ featured_merchants }) => {
  return (
    <div>
      {/* <!-- Team --> */}
      <section className="pt-18 pb-20 md:pt-0 md:pb-8">
        <div className="container">
          <HeadLine
            text="Featured Sellers"
            classes="font-display text-jacarta-700 mb-12 text-center text-3xl dark:text-white"
          />
          <div className="relative">
           
              <div className="grid grid-cols-3 gap-4 sm:grid-cols-3 md:grid-cols-4 md:gap-[1.875rem] lg:grid-cols-7">
              {featured_merchants &&
                featured_merchants.slice(0, 14).map((item,index) => {
                  const { _id, thumbnail, legal_name, name, slug } = item;
                  return (
                      <Link href={`/merchant/${slug || _id}`} key={`merchant-${index}`}>
                        <div>
                          <img
                            src={`${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}${
                              thumbnail ? "/" + thumbnail : "/no_product.png"
                            }`}
                            className="rounded-full mx-auto mb-6 h-[4rem] w-[4rem] md:h-[8.125rem] md:w-[8.125rem] cursor-pointer"
                            alt={name}
                          />
                          <h3 className="font-display text-center text-jacarta-700 text-md dark:text-white">
                            {legal_name}
                          </h3>
                        </div>
                      </Link>
                  );
                })}
                </div>
           
          </div>
        </div>
      </section>
      {/* <!-- end team --> */}
    </div>
  );
};

export default Team;
