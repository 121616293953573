import React from "react";
import Link from "next/link";

const CTA = () => {
  return (
    <section className="dark:bg-jacarta-800 relative py-10 md:pt-8">
      <div className="container">
        <div className="mx-auto max-w-lg text-center">
          <h1 className="text-jacarta-700 font-display mb-6 text-2xl dark:text-white md:text-3xl">
            Open for business
          </h1>
          <p className="dark:text-jacarta-300 mb-12 text-lg leading-normal">
            Whatever liquidation you sell, we'll help you connect with buyers
          </p>
          <Link href="/create">
            <a className="bg-accent shadow-accent-volume hover:bg-accent-dark inline-block rounded-full py-3 px-8 text-center font-semibold text-white transition-all">
             Become a Likizone Pro today!
            </a>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default CTA;
